@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.denied-access {
  width: 100%;
  height: 93vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 3.15vh;
}
.denied-access-title {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 1;
}
.denied-access-text {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1;
  max-width: 54.86vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.75vh;
  text-align: center;
}
.denied-access-back-container {
  justify-self: flex-start;
  align-self: flex-start;
}
.denied-access-img-container {
  max-width: 28.47vw;
}
.denied-access-img {
  width: 100%;
  height: auto;
}
.rule-card {
  width: 37.98vw;
  max-height: 5.46875vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.rule-card-title-block {
  padding-top: 1.5625vh;
  padding-right: 0vw;
  padding-bottom: 1.5625vh;
  padding-left: 0vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.rule-card-description {
  opacity: 0;
  transition: 0.5s all ease;
}
.rule-card-show {
  max-height: 100%;
}
.rule-show-description {
  opacity: 1;
}
