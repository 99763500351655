@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.rules {
  width: 100%;
  height: 100vh;
}
.rules-title-block {
  color: #fff;
  min-height: 34.66vh;
  margin-top: 2.53vh;
  background: #cc5de8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.75vh;
}
.rules-title-h2 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 2.33rem;
  line-height: 1;
}
.rules-paragraph {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1;
  width: 62.63%;
  text-align: center;
}
.rules-box-container {
  display: flex;
  justify-content: space-between;
}
.rules-box {
  padding-top: 9.1796875vh;
  padding-right: 5.555555555555555vw;
  padding-bottom: 0vh;
  padding-left: 5.555555555555555vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 3.9vh;
}
.rules-content {
  cursor: pointer;
  align-self: flex-start;
  width: 36.11vw;
  max-height: 5.46vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 1.17vh;
  transition: all 1s;
}
.rules-content-title {
  padding-top: 1.3671875vh;
  padding-right: 0vw;
  padding-bottom: 1.3671875vh;
  padding-left: 0vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.rules-content-title-h3 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
}
.rules-content-show {
  max-height: 30.91vh;
}
.rules-content-icon-container {
  transition: all 0.5s ease;
}
.rules-rotate-icon {
  transform: rotate(180deg);
}
