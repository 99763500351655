@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.rules-full-description {
  display: flex;
  flex-direction: column;
  row-gap: 2.34vh;
}
.rules-description-title {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  align-items: center;
  column-gap: 0.27vw;
}
.rules-full-description-title-block {
  display: flex;
  flex-direction: column;
  row-gap: 0.97vh;
}
