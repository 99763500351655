@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.back-wrapper {
  padding-top: 2.5390625vh;
  padding-right: 0vw;
  padding-bottom: 0vh;
  padding-left: 3.194444444444444vw;
  box-sizing: border-box;
  width: 100%;
}
.back {
  display: flex;
  align-items: center;
  column-gap: 1.38vw;
  cursor: pointer;
}
.back path {
  stroke: #000;
  transition: 0.5s;
}
.back:hover .back-text {
  color: #cc5de8;
}
.back:hover path {
  stroke: #cc5de8;
}
.back-text {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  transition: 0.5s;
}
