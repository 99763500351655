* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 1.111vw
}

::-webkit-scrollbar {
  display: block;
  width: 10px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #C92DEC;
  border-radius: 60px;
  border-right: none;
  border-left: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #CC5DE8;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 5px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 5px;
}