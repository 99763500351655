@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.loader {
  animation: rotate 2s infinite;
  height: 3.47vw;
  width: 3.47vw;
}
.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.39vw;
  width: 1.39vw;
}
.loader:before {
  animation: ball1 2s infinite;
  background-color: #cc5de8;
  box-shadow: 2.08vw 0 0 #cc5de8;
  margin-bottom: 0.69vw;
}
.loader:after {
  animation: ball2 2s infinite;
  background-color: #cc5de8;
  box-shadow: 2.08vw 0 0 #cc5de8;
}
.text-loader {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  width: fit-content;
  color: #000;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}
.text-loader::after {
  width: 0.34vw;
  height: 0.34vw;
  content: '';
  background: currentColor;
  position: absolute;
  bottom: 27%;
  right: -3%;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
  border-radius: 50%;
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes ball1 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-bottom: 0;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-bottom: 0.69vw;
  }
}
@-webkit-keyframes ball1 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-bottom: 0;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-bottom: 0.69vw;
  }
}
@-o-keyframes ball1 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-bottom: 0;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-bottom: 0.69vw;
  }
}
@keyframes ball1 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-bottom: 0;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-bottom: 0.69vw;
  }
}
@-moz-keyframes ball2 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-top: -1.39vw;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-top: 0;
  }
}
@-webkit-keyframes ball2 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-top: -1.39vw;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-top: 0;
  }
}
@-o-keyframes ball2 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-top: -1.39vw;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-top: 0;
  }
}
@keyframes ball2 {
  0% {
    box-shadow: 2.08vw 0 0 #cc5de8;
  }
  50% {
    box-shadow: 0 0 0 #cc5de8;
    margin-top: -1.39vw;
    transform: translate(1.04vw, 1.04vw);
  }
  100% {
    box-shadow: 2.08vw 0 0 #cc5de8;
    margin-top: 0;
  }
}
@-moz-keyframes animloader {
  0% {
    opacity: 0;
    box-shadow: 0px 0 rgba(255,255,255,0), 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  25% {
    box-shadow: 0px 0 #000, 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  50% {
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 rgba(255,255,255,0);
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 #000;
  }
}
@-webkit-keyframes animloader {
  0% {
    opacity: 0;
    box-shadow: 0px 0 rgba(255,255,255,0), 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  25% {
    box-shadow: 0px 0 #000, 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  50% {
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 rgba(255,255,255,0);
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 #000;
  }
}
@-o-keyframes animloader {
  0% {
    opacity: 0;
    box-shadow: 0px 0 rgba(255,255,255,0), 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  25% {
    box-shadow: 0px 0 #000, 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  50% {
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 rgba(255,255,255,0);
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 #000;
  }
}
@keyframes animloader {
  0% {
    opacity: 0;
    box-shadow: 0px 0 rgba(255,255,255,0), 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  25% {
    box-shadow: 0px 0 #000, 0.625rem 0 rgba(255,255,255,0), 1.25rem 0 rgba(255,255,255,0);
  }
  50% {
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 rgba(255,255,255,0);
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0 #000, 0.625rem 0 #000, 1.25rem 0 #000;
  }
}
